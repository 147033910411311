<template>
	<div class="d-flex flex-column settingsmappanel max-height-100">
		<div class="d-flex flex-column py-3 px-3 bottomborder" ref="header">
			<div class="settings-title fw-bold">{{ $t('panels.configpannel.mapconfig') }}</div>
			<div class="settings-subtitle fw-regular fs-sm">{{ $t('panels.configpannel.subtitle') }}</div>
		</div>

		<div class="lists pb-0">
			<v-list flat dense style="background: transparent" class="pt-0">
				<!-- Vectorial Layers -->
				<div v-if="showLayers">
					<v-list-group id="layerlist" :ripple="false" v-model="isOpened.layers">
						<template #activator>
							<v-list-item-title
								class="maintitle fw-bold ml-n4"
								:title="$t('panels.configpannel.vectorialLayers')"
								:class="isOpened.layers ? 'color-primary' : 'color-black'"
								>{{ $t('panels.configpannel.vectorialLayers') }}</v-list-item-title
							>
						</template>

						<template #prependIcon>
							<v-icon
								class="fw-500"
								:color="isOpened.layers ? 'var(--azul-primary-100)' : '#000'"
								size="16"
								:title="$t('panels.configpannel.vectorialLayers')"
								>fak fa-solid-layers</v-icon
							>
						</template>

						<template #appendIcon>
							<v-icon
								class="fw-500 list-icon"
								:class="{ 'list-icon--opened': isOpened.layers }"
								:color="isOpened.layers ? 'var(--azul-primary-100)' : '#0000008A'"
								size="13"
								>fak fa-solid-down</v-icon
							>
						</template>

						<v-list-item>
							<layers-list ref="layerlist" />
						</v-list-item>
					</v-list-group>
				</div>

				<v-divider v-if="showLayers" style="height: 1px; background: rgba(0, 0, 0, 0.05)" :class="{ 'mt-2': isOpened.layers }" />

				<!-- Notification Layers -->
				<div v-if="showNotifications && hasNotifications">
					<v-list-group id="notificationlayerlist" :ripple="false" v-model="isOpened.notificationlayers">
						<template #activator>
							<v-list-item-title class="maintitle fw-bold ml-n4" :title="$t('panels.configpannel.notificationLayers')">{{
								$t('panels.configpannel.notificationLayers')
							}}</v-list-item-title>
						</template>

						<template #prependIcon>
							<v-icon
								class="fw-500"
								:color="isOpened.notificationlayers ? 'var(--azul-primary-100)' : '#000'"
								size="16"
								:title="$t('panels.configpannel.notificationLayers')"
								>fak fa-solid-notification</v-icon
							>
						</template>

						<template #appendIcon>
							<v-icon class="fw-bold list-icon" :class="{ 'list-icon--opened': isOpened.notificationlayers }" size="13"
								>fak fa-solid-down</v-icon
							>
						</template>

						<v-list-item>
							<notification-layers-list ref="notificationlayerlist" />
						</v-list-item>
					</v-list-group>
				</div>

				<v-divider
					v-if="showNotifications && hasNotifications"
					style="height: 1px; background: rgba(0, 0, 0, 0.05)"
					:class="{ 'mt-2': isOpened.notificationlayers }"
				/>

				<!-- Raster Layers -->
				<div v-if="useSatelliteImages">
					<v-list-group :id="rasterId" :ripple="false" v-model="isOpened.layersrasterSettings">
						<template #activator>
							<v-list-item-title class="maintitle fw-bold ml-n4" :title="$t('panels.configpannel.satelliteimage')">{{
								$t('panels.configpannel.satelliteimage')
							}}</v-list-item-title>
						</template>

						<template #prependIcon>
							<v-icon
								class="fw-500"
								:color="isOpened.layersrasterSettings ? 'var(--azul-primary-100)' : '#000'"
								size="16"
								:title="$t('panels.configpannel.satelliteimage')"
								>fak fa-satellite</v-icon
							>
						</template>

						<template #appendIcon>
							<v-icon class="fw-bold list-icon" :class="{ 'list-icon--opened': isOpened.layersrasterSettings }" size="13"
								>fak fa-solid-down</v-icon
							>
						</template>

						<v-list-item>
							<raster-tools :rastertools="'rastertoolsCompareMap1'" ref="rastertools" />
						</v-list-item>

						<v-list-item class="pa-0 mx-4">
							<layers-raster-list :rasterlist="rasterId" :key="0" />
						</v-list-item>
					</v-list-group>
					<v-divider v-show="!isOpened.filters" style="height: 1px; background: rgba(0, 0, 0, 0.05)" />
				</div>
				<!-- External Raster Layers -->
				<div v-if="showExternalLayers">
					<v-list-group id="externallayerlist" :ripple="false" v-model="isOpened.externalrasterlayers">
						<template #activator>
							<v-list-item-title
								class="maintitle fw-bold ml-n4"
								:color="isOpened.externalrasterlayers ? 'var(--azul-primary-100)' : '#000'"
								:title="$t('panels.configpannel.externallayers')"
								>{{ $t('panels.configpannel.externallayers') }}</v-list-item-title
							>
						</template>

						<template #prependIcon>
							<v-icon
								class="fw-500"
								:color="isOpened.externalrasterlayers ? 'var(--azul-primary-100)' : '#000'"
								size="16"
								:title="$t('panels.configpannel.externallayers')"
								>fak fa-solid-open</v-icon
							>
						</template>

						<template #appendIcon>
							<v-icon class="fw-bold list-icon" :class="{ 'list-icon--opened': isOpened.externalrasterlayers }" size="13"
								>fak fa-solid-down</v-icon
							>
						</template>

						<v-list-item>
							<external-raster-layers-list ref="externallayerlist" />
						</v-list-item>
					</v-list-group>
					<v-divider v-show="!isOpened.filters" style="height: 1px; background: rgba(0, 0, 0, 0.05)" />
				</div>

				<!-- GeoJSON Layers -->
				<div v-if="showGeoJsonLayers">
					<v-list-group id="geojsonlayerlist" :ripple="false" v-model="isOpened.geojsonlayers">
						<template #activator>
							<v-list-item-title
								class="maintitle fw-bold ml-n4"
								:color="isOpened.geojsonlayers ? 'var(--azul-primary-100)' : '#000'"
								:title="$t('panels.configpannel.geojsonlayers')"
								>{{ $t('panels.configpannel.geojsonlayers') }}</v-list-item-title
							>
						</template>

						<template #prependIcon>
							<v-icon
								class="fw-500"
								:color="isOpened.geojsonlayers ? 'var(--azul-primary-100)' : '#000'"
								size="16"
								:title="$t('panels.configpannel.geojsonlayers')"
								>fa fa-solid fa-globe</v-icon
							>
						</template>

						<template #appendIcon>
							<v-icon class="fw-bold list-icon" :class="{ 'list-icon--opened': isOpened.geojsonlayers }" size="13"
								>fak fa-solid-down</v-icon
							>
						</template>
						<!-- <v-list-item>
							<geo-json-tools ref="rastertools" />
						</v-list-item> -->
						<v-list-item>
							<geo-json-layers-list ref="geojsonlayerlist" />
						</v-list-item>
					</v-list-group>
					<v-divider v-show="!isOpened.filters" style="height: 1px; background: rgba(0, 0, 0, 0.05)" />
				</div>

				<!-- AQI Layers
				<div v-if="showAqiLayers">
					<v-list-group id="aqilayerlist" :ripple="false" v-model="isOpened.aqilayers">
						<template #activator>
							<v-list-item-title class="maintitle ml-n4" :title="$t('panels.configpannel.aqiLayers')">{{ $t('panels.configpannel.aqiLayers') }}</v-list-item-title>
						</template>

						<template #prependIcon>
							<img
								src="../../../../../assets/aqi/airQuality.svg"
								alt="AQI Panel"
								class="mb-3"
								style="height: 20px"
								v-if="!isOpened.aqilayers"
							/>
							<img src="../../../../../assets/aqi/airQuality-accent.svg" alt="AQI Panel" class="mb-3" style="height: 20px" v-else />
						</template>

						<template #appendIcon>
							<v-icon class="fw-bold list-icon" :class="{ 'list-icon--opened': isOpened.aqilayers }" size="13"
								>fas fa-chevron-down</v-icon
							>
						</template>

						<v-list-item>
							<aqi-layers-list ref="aqilayerlist" />
						</v-list-item>
					</v-list-group>
					<v-divider v-show="!isOpened.filters" style="height: 1px; background: rgba(0, 0, 0, 0.05)" />
				</div>-->
			</v-list>
		</div>
	</div>
</template>

<script>
//import { isSuperAdmin, search } from '@/api/common';

import LayersList from './LayersList';
import NotificationLayersList from './NotificationLayersList';
import LayersRasterList from './LayersRasterList';
import ExternalRasterLayersList from './ExternalRasterLayersList';
import GeoJsonLayersList from './GeoJsonLayersList';
import jsonFileImage from '@/assets/images/json_file.png';
//import AqiLayersList from './AqiLayersList';
import RasterTools from './RasterTools';
import constants from '@/utils/constants';
//import GeoJsonTools from './GeoJsonTools';

export default {
	name: 'settingsmappanel',
	data() {
		return {
			jsonFile_icon: jsonFileImage,
			rasterId: constants.RASTER.SETTINGS,
			useSatelliteImages: false
		};
	},
	components: {
		LayersList,
		NotificationLayersList,
		LayersRasterList,
		ExternalRasterLayersList,
		GeoJsonLayersList,
		//AqiLayersList,
		RasterTools
		//GeoJsonTools
	},

	computed: {
		isOpened() {
			return this.$store.state.settingsPanel.isOpened;
		},
		showExternalLayers() {
			return (
				this.$store.getters.getPermissions['APP_EXTERNAL_LAYERS'] &&
				this.$store.getters.externalLayers &&
				Object.keys(this.$store.getters.externalLayers).length > 0
			);
		},
		showLayers() {
			return Object.values(this.$store.getters.layers).filter((layer) => layer.model != constants.SATELLITE_MODEL).length > 0;
			//return Object.keys(this.$store.getters.layers).length > 0;
		},
		showNotifications() {
			return false; // to hide notifications layer group
		},
		showGeoJsonLayers() {
			return (
				this.$store.getters.getPermissions['APP_GEOJSON_LAYERS'] &&
				this.$store.getters.getGeoJsonLayersData &&
				Object.keys(this.$store.getters.getGeoJsonLayersData).length > 0
			);
		},
		hasNotifications() {
			console.log(
				'debug 1122',
				Object.values(this.$store.getters.layers).filter((layer) => layer.model == constants.NOTIFICATIONS_MODEL),
				Object.values(this.$store.getters.layers).filter((layer) => layer.model == constants.NOTIFICATIONS_MODEL).length > 0
			);
			return Object.values(this.$store.getters.layers).filter((layer) => layer.model == constants.NOTIFICATIONS_MODEL).length > 0;
		}
	},
	watch: {
		isOpened: {
			handler(newValue, oldValue) {
				console.log('debug isopened settingsmappanel', oldValue, newValue);
			}
		}
	},
	methods: {}
};
</script>

<style lang="postcss">
@import '../../../../../styles/eiffel-variables.pcss';

.settingsmappanel {
	& .list-icon {
		color: #000;

		&--opened {
			transform: rotateZ(-90deg) !important;
		}
	}

	& .lists {
		overflow-x: hidden;
		overflow-y: auto;

		& .maintitle {
			font-size: 1rem !important;
		}
	}

	& .settings-title {
		font-size: 1.3rem !important;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.2;
		letter-spacing: normal;
		color: #000;
		margin-bottom: 2px;
	}

	& .settings-subtitle {
		font-style: normal;
		font-stretch: normal;
		line-height: 1.33 !important;
		letter-spacing: normal;
		color: var(--gris-500) !important;
		margin-top: 2px !important;
	}
}

.bottomborder {
	border-bottom: 1px solid var(--moderate);
}
</style>
